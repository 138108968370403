<template>
  <div class="pa-3">
    <b-text-field v-model="name" label="Name" required />
    <div class="d-flex">
      <div class="flex-1" />
      <div class="flex-1">
        <b-switch v-model="isActive" label="Active" />
      </div>
      <div class="flex-1" />
    </div>
    <submission-triggers
      store="deliveryRule"
      :entity-name="entityName"
      :match-data="matchData"
    />
  </div>
</template>

<script type="text/babel">
import SubmissionTriggers from "../submission-trigger/SubmissionTriggers";
import { createNamespacedHelpers } from "@/store/helpers";
import BTextField from "@/components/generic/BTextField";
import BSwitch from "@/components/generic/BSwitch";

const { mapComputed } = createNamespacedHelpers("deliveryRule");

export default {
  name: "delivery-rule",
  inject: ["$validator"],
  components: {
    BSwitch,
    BTextField,
    SubmissionTriggers
  },
  props: {
    matchData: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    entityName: "deliveryRule",
    submissionIdToCheck: null,
    triggerGroupMatchData: undefined
  }),
  computed: {
    ...mapComputed(["name", "isActive"])
  }
};
</script>

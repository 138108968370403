<template>
  <v-card color="white">
    <crud-header
      :title="name"
      type="Delivery Rule"
      store="deliveryRule"
      @is-changed="setIsChanged"
      :tabs="tabs"
    >
      <submission-trigger-check-dialog
        v-if="!changed"
        slot="toolbarActions"
        :ruleId="id"
        api-module="deliveryRule"
        :trigger-groups="triggerGroups"
        :submission-id.sync="submissionIdToCheck"
        :trigger-group-match-data.sync="triggerGroupMatchData"
      />
    </crud-header>
  </v-card>
</template>

<script type="text/babel">
import deliveryRuleModule from "@/store/modules/delivery-rule";
import CrudHeader from "../../components/generic/crud/CrudHeader";
import { createNamespacedHelpers } from "vuex";
import { mapDetailViewLifecycleMethods } from "../helpers";
import SubmissionTriggerCheckDialog from "@/components/submission-trigger/SubmissionTriggerCheckDialog";
import { SET_RULE_TRIGGER_GROUP_MATCH_DATA } from "@/store/modules/rule-trigger-groups/mutation-types";

const { mapGetters } = createNamespacedHelpers("deliveryRule");

const detailViewLifecycleMethods = mapDetailViewLifecycleMethods(
  "deliveryRule",
  deliveryRuleModule
);

export default {
  name: "delivery-rule-view",
  $_veeValidate: {
    validator: "new" // give me my own validator scope.
  },
  components: {
    SubmissionTriggerCheckDialog,
    CrudHeader
  },
  data: () => ({
    submissionIdToCheck: null,
    changed: false
  }),
  computed: {
    ...mapGetters(["id", "name"]),
    triggerGroups() {
      return this.$store.state.deliveryRule.ruleTriggerGroups.model;
    },
    triggerGroupMatchData: {
      get() {
        return this.$store.state.deliveryRule.ruleTriggerGroups
          .ruleTriggerGroupMatchData;
      },
      set(value) {
        this.$store.commit(
          `deliveryRule/ruleTriggerGroups/${SET_RULE_TRIGGER_GROUP_MATCH_DATA}`,
          value
        );
      }
    },
    tabs() {
      return [
        {
          name: "Dashboard",
          to: {
            name: "delivery-rules.show",
            params: { deliveryRuleId: this.id }
          }
        }
      ];
    }
  },
  methods: {
    setIsChanged(value) {
      this.changed = value;
    }
  },
  ...detailViewLifecycleMethods
};
</script>

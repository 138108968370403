<template>
  <v-card color="white">
    <crud-header
      :title="name"
      type="Delivery Rule"
      store="deliveryRule"
      @save-success="
        ({ id }) =>
          $router.push({
            name: 'delivery-rules.show',
            params: { deliveryRuleId: id }
          })
      "
    />
    <delivery-rule />
  </v-card>
</template>

<script type="text/babel">
import deliveryRuleModule from "@/store/modules/delivery-rule";
import CrudHeader from "../../components/generic/crud/CrudHeader";
import { createNamespacedHelpers } from "vuex";
const { mapGetters } = createNamespacedHelpers("deliveryRule");
import { mapCreateViewLifecycleMethods } from "../helpers";
import DeliveryRule from "../../components/delivery-rule/DeliveryRule";

const createViewLifecycleMethods = mapCreateViewLifecycleMethods(
  "deliveryRule",
  deliveryRuleModule
);

export default {
  name: "delivery-create-view",
  components: {
    CrudHeader,
    DeliveryRule
  },
  data: () => ({}),
  computed: {
    ...mapGetters(["name"])
  },
  ...createViewLifecycleMethods
};
</script>

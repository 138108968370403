<template>
  <tr>
    <td>{{ deliveryRule.name }}</td>
    <td class="text-sm-right">
      <v-icon v-if="deliveryRule.isActive" color="green">
        check
      </v-icon>
      <v-icon v-else color="grey">
        clear
      </v-icon>
    </td>
    <td>
      <v-btn
        icon
        :to="{ name: 'delivery-rules.show', params: { deliveryRuleId } }"
      >
        <v-icon>far fa-search</v-icon>
      </v-btn>
    </td>
  </tr>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import { FIND_BY_ID } from "@/store/templates/table/getter-types";

const { mapGetters: mapAuthGetters } = createNamespacedHelpers("auth");
const { mapGetters: mapDeliveryRulesGetters } = createNamespacedHelpers(
  "deliveryRules"
);

export default {
  name: "refinement-rule-table-row",
  props: {
    deliveryRuleId: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    deliveryTypes: window.config.enums.submissionRuleDeliveryTypes
  }),
  computed: {
    ...mapAuthGetters(["hasRolesOrPermissions"]),
    ...mapDeliveryRulesGetters([FIND_BY_ID]),
    deliveryRule() {
      return this[FIND_BY_ID](this.deliveryRuleId);
    }
  }
};
</script>

<template>
  <div>
    <delivery-rule :match-data="triggerGroupMatchData" />
  </div>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import DeliveryRule from "@/components/delivery-rule/DeliveryRule";
const { mapGetters } = createNamespacedHelpers("deliveryRule");

export default {
  name: "delivery-Rule-dashboard-view",
  components: {
    DeliveryRule
  },
  computed: {
    ...mapGetters([]),
    triggerGroupMatchData() {
      return this.$store.state.deliveryRule.ruleTriggerGroups
        .ruleTriggerGroupMatchData;
    }
  },
  data: () => ({})
};
</script>

<template>
  <table-overview
    v-if="!showChild"
    title="Delivery Rules"
    store="deliveryRules"
    :headers="[
      { text: 'Name', value: 'name' },
      { text: 'Active', align: 'right', value: 'is_active', width: '100px' },
      {
        text: 'Actions',
        align: 'right',
        value: 'name',
        sortable: false,
        width: '100px'
      }
    ]"
    :actions="actions"
    searchable
    force-initial-sort
  >
    <delivery-rule-table-row
      slot="table-row"
      slot-scope="{ item }"
      :delivery-rule-id="item"
    />
  </table-overview>
  <router-view v-else />
</template>
<script type="text/babel">
import TableOverview from "../../components/generic/overview/TableOverview";
import deliveryRulesModule from "@/store/modules/delivery-rules";
import DeliveryRuleTableRow from "../../components/delivery-rule/DeliveryRuleTableRow";
import { mapOverviewLifecycleMethods } from "../helpers";

const overviewLifecycleMethods = mapOverviewLifecycleMethods(
  "deliveryRules",
  deliveryRulesModule
);

export default {
  name: "delivery-rules-view",
  components: {
    DeliveryRuleTableRow,
    TableOverview
  },
  data: () => ({
    actions: [
      {
        icon: "far fa-fw fa-plus",
        color: "pink",
        to: { name: "delivery-rules.create" },
        rolesOrPermissions: "delivery_rule_create"
      }
    ]
  }),
  computed: {
    showChild() {
      return (
        this.$route.matched.filter(route => route.name !== undefined).length > 1
      );
    }
  },
  ...overviewLifecycleMethods
};
</script>
